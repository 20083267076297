<template>
<div class="app-calendar overflow-hidden border">
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row class="mb-2">

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedClass" :options="classes" class="w-100" :getOptionLabel="option => option.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildClass" :options="childClasses" class="w-100" :reduce="val => val.id" :getOptionLabel="option => option.name" />
                </b-col>

                <b-col cols="12" md="12" class="mb-2 mt-2">
                    <b-button variant="success" block @click="getSyllabusData()">FİLTRELE</b-button>
                </b-col>
                <b-col cols="12" md="12" class="mb-2 mt-2">
                    <b-button variant="primary" block @click="downloadExcel()">İNDİR</b-button>
                </b-col>
            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>
    <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                    <full-calendar ref="calendarRef" :options="calendarOptions" class="full-calendar" />
                </div>
            </div>
        </div>
    </div>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Ders Kaydı</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Alt Sınıf" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.classId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="childClasses" />
                </b-form-group>
                <!-- Code -->
                <b-form-group label="Öğretmen" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.teacherId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" :getOptionLabel="option => option.firstName+' '+option.lastName" :options="teachers" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Ders" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.lessonId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="lessons" />
                </b-form-group>

                <b-form-group label="Tarihi" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.date" class="form-control" :config="{ enableTime: false,time_24hr:true,weekNumbers:true,dateFormat: 'd-m-Y',locale:'en'}" />
                </b-form-group>

                <b-form-group label="Başlangıç Saati" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.startTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'en'}" />
                </b-form-group>

                <b-form-group label="Bitiş Saati" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.endTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'en'}" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addSyllabus">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yoklama</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <b-table :items="editSyllabusRequest.items" responsive :fields="fields" class="mb-0">
                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <!-- Id -->
                <template #cell(username)="data">
                    <span class="font-weight-bolder mb-12">{{
                    (data.item.user!=null)?
                    data.item.user.firstName+' '+data.item.user.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
                </template>

                <template #cell(isAttendance)="data">
                    <b-badge pill :variant="getStatus(data.item.isAttendance)">
                        {{getStatusText(data.item.isAttendance)}}
                    </b-badge>
                </template>

            </b-table>

        </template>
    </b-sidebar>

</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import XLSX from 'xlsx';
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BProgress,
    BOverlay,
    BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
export default {
    components: {
        flatPickr,
        FullCalendar,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BProgress,
        BOverlay,
        vSelect,
        BSidebar
    },
    data() {
        return {
            fields: [
                { key: 'schoolNumber', label: 'Okul Numarası' },
                { key: 'username', label: 'Öğrenci Adı Soyadı' },

                { key: 'isAttendance', label: 'Durum' }
            ],
            calendar: {},
            loading: false,
            addPanel: false,
            editPanel: false,
            classes: [],
            childClasses: [],
            teachers: [],
            lessons: [],
            selectedClass: {},
            selectedChildClass: {},
            oldData: [],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'listMonth',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: '' //end: 'listMonth',
                },
                editable: false,
                eventResizableFromStart: false,
                eventClick: this.handleEvent,
                eventClassNames: this.eventClassName,
                dragScroll: false,
                dayMaxEvents: 3,
                locale: 'tr',
                navLinks: true,

                rerenderDelay: 350,
                events: []
            },
            addSyllabusRequest: {
                parentClass: '',
                classId: '',
                lessonId: '',
                teacherId: '',
                date: '',
                startTime: '',
                endTime: ''
            },
            editSyllabusRequest: {
                id: '',
                parentClass: '',
                items: [],
                classId: '',
                lessonId: '',
                teacherId: '',
                date: '',
                startTime: '',
                endTime: ''
            }
        }
    },
    created() {
        this.getData();
        this.getTeacher();
        this.getLessons();
        this.$root.$on('AddButtonClick', () => {
            //console.log("Add Button Call");
            this.addPanel = true;
        });
    },
    watch: {
        selectedClass: function (val) {
            this.getChildClass(val);
        }
    },
    methods: {
        getStatus(status) {
            switch (status) {
                case "NotCame":
                    return 'light-danger';
                case "Empty":
                    return 'light-info';
                case "Came":
                    return 'light-success';
            }
        },
        getStatusText(status) {
            switch (status) {
                case "NotCame":
                    return 'Gelmedi';
                case "Empty":
                    return 'Belirtilmedi';
                case "Came":
                    return 'Geldi';
            }
        },
        async getData() {
            this.loading = true;
            var classes = await this.$http.get("Branch/Classes");
            this.classes = classes.data.data;
            this.loading = false;
        },
        async getTeacher() {
            var users = await this.$http.get("Branch/Teachers");
            this.teachers = users.data.data;
        },
        async getLessons() {
            var lessons = await this.$http.get("Branch/Lessons");
            this.lessons = lessons.data.data;
        },
        async getAttendance(attendanceId) {
            try {
                var response = await this.$http.get("Report/StudentAttendance/" + attendanceId);
                return response.data.data;
            } catch (error) {
                var r = { data: { data: { attandance: { isAttendance: false } } } };
                return r;
            }

        },
        async getSyllabusData() {
            this.loading = true;
            this.calendarOptions.events = [];
            var response = await this.$http.get("Branch/Syllabus/" + this.selectedChildClass);
            var api = this.$refs.calendarRef.getApi();
            this.clearAllEvents();
            this.oldData = response.data.data;

            await response.data.data.forEach(async (element) => {
                this.loading = true;
                //console.log(element.id + element.lesson.name );

                //event attandance
                element.attandance = await this.getAttendance(element.id);
                var lessonColor = "";
                element.attandance.forEach(lesson => {
                    if (lesson.isAttendance == "Empty") {
                        lessonColor = "red";
                    }
                });
                //
                await api.addEvent({
                    id: element.id,
                    title: element.lesson.name + " " + element.teacher.firstName + " " + element.teacher.lastName,
                    date: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.startTime,
                    end: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.endTime,
                    create: true,
                    borderColor: lessonColor
                });
            });

            api.refetchEvents();
            await api.render();
            this.loading = false;
        },
        convertToDate(date) {
            //console.log(date);
            var d = date.split(" "); // d-m-y h:i
            var dmy = d[0].split("-"); // d-m-y
            var dh = d[1].split(":"); //  h:i
            var r = new Date(dmy[2], parseInt(dmy[1]) - 1, dmy[0], dh[0], dh[1]);
            // console.log(r.toLocaleString());
            return r;
        },
        async downloadExcel() {
            var ws_data = new Array();
            var lessonsrow = new Array();
            var teachersrow = new Array();
            var datesrow = new Array();
            var hoursrow = new Array();
            lessonsrow.push(""); //"Ders Adı");  
            teachersrow.push(""); //"Öğretmen Adı"); 
            datesrow.push(""); //"Tarih");
            hoursrow.push(""); //"Saat");

            this.oldData.forEach(lesson => {
                var lessontoDate = this.convertToDate(lesson.date);
                var calendarDate = this.$refs.calendarRef.getApi().currentData.currentDate;
                var calendarThisMonthStart = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), 1);
                var calendarNextMonth = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 1);

                if (lessontoDate < calendarThisMonthStart) return;
                if (lessontoDate > calendarNextMonth) return;

                lessonsrow.push(lesson.lesson.name);
                teachersrow.push(lesson.teacher.firstName + " " + lesson.teacher.lastName);
                datesrow.push(lessontoDate.toLocaleDateString());
                hoursrow.push(lesson.startTime);
                //{{getStatusText(data.item.isAttendance)}}
            });

            ws_data.push(lessonsrow);
            ws_data.push(teachersrow);
            ws_data.push(datesrow);
            ws_data.push(hoursrow);
            var _this = this;
            this.oldData[0].attandance.forEach(function (attandance, i) {
                var studentRow = new Array();
                studentRow.push(attandance.user.firstName + " " + attandance.user.lastName);
                _this.oldData.forEach(function (lesson, x) {
                    var lessontoDate = _this.convertToDate(lesson.date);
                    var calendarDate = _this.$refs.calendarRef.getApi().currentData.currentDate;
                    var calendarThisMonthStart = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), 1);
                    var calendarNextMonth = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 1);
                    if (lessontoDate < calendarThisMonthStart) return;
                    if (lessontoDate > calendarNextMonth) return;
                    studentRow.push(_this.getStatusText(lesson.attandance[i].isAttendance));
                });
                ws_data.push(studentRow);

            });

            //Tarihe Göre Sıralama
            var satir = ws_data[0];
            for (let x = 1; x < ws_data[0].length; x++) {
                for (let y = x + 1; y < ws_data[0].length; y++) {
                    var sutunzaman = this.convertToDate((ws_data[2][x]).replace(/\./g, "-") + " " + ws_data[3][x]); // + ws_data[3][x]);
                    var sutunzamany = this.convertToDate((ws_data[2][y]).replace(/\./g, "-") + " " + ws_data[3][y]); // + ws_data[3][y]);
                    if (sutunzaman > sutunzamany) {
                        for (let s = 0; s < ws_data.length; s++) {
                            var temp = ws_data[s][x];
                            ws_data[s][x] = ws_data[s][y];
                            ws_data[s][y] = temp;
                        }
                    }
                }

            }

            var wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Yoklama",
                Subject: "Yoklama",
                Author: "Yes Akademi",
                CreatedDate: new Date()
            };

            var ws = XLSX.utils.aoa_to_sheet(ws_data);
            wb.SheetNames.push("Sayfa1");
            ws["!cols"] = [{ width: 50 }]; //student names width
            for (let index = 0; index < 30; index++) {
                ws["!cols"].push({ width: 25 });

            }

            wb.Sheets["Sayfa1"] = ws;
            await XLSX.writeFile(wb, "yoklama.xlsx");

        },
        clearAllEvents() {
            var api = this.$refs.calendarRef.getApi();
            var eventSource = api.getEvents();
            eventSource.forEach(event => {
                event.remove();
            });
        },
        async getChildClass(classId) {
            this.loading = true;
            var childClasses = await this.$http.get("Branch/Classes/" + classId);
            this.childClasses = childClasses.data.data;
            this.loading = false;
        },
        addSyllabus() {
            var request = new FormData();
            request.append("parentClass", this.addSyllabusRequest.parentClass);
            request.append("classId", this.addSyllabusRequest.classId);
            request.append("lessonId", this.addSyllabusRequest.lessonId);
            request.append("teacherId", this.addSyllabusRequest.teacherId);
            request.append("date", this.addSyllabusRequest.date);
            request.append("startTime", this.addSyllabusRequest.startTime);
            request.append("endTime", this.addSyllabusRequest.endTime);
            this.$http.post("Branch/AddSyllabus", request).then((data) => {
                    this.getSyllabusData();
                    this.addPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        editSyllabus() {

            var request = new FormData();
            request.append("id", this.editSyllabusRequest.id);
            request.append("parentClass", this.editSyllabusRequest.parentClass);
            request.append("classId", this.editSyllabusRequest.classId);
            request.append("lessonId", this.editSyllabusRequest.lessonId);
            request.append("teacherId", this.editSyllabusRequest.teacherId);
            request.append("date", this.editSyllabusRequest.date);
            request.append("startTime", this.editSyllabusRequest.startTime);
            request.append("endTime", this.editSyllabusRequest.endTime);

            this.$http.put("Branch/UpdateSyllabus", request).then((data) => {
                    this.getSyllabusData();
                    this.editPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        removeSyllabus() {
            this.$confirm({
                title: 'Onayla',
                message: `Onaylamak İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Onayla'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$http.delete("Branch/DeleteSyllabus/" + this.editSyllabusRequest.id);
                        this.getSyllabusData();
                        this.editPanel = false;
                    }
                }
            });

        },
        eventClassName: function (arg) {

            return [
                // Background Color
                `bg-light-primary`,
            ]

        },
        handleEvent: async function (arg) {
            var event = arg.event;
            //var response = await this.$http.get("Report/StudentAttendance/"+event.id); 

            var data = this.oldData.filter(x => x.id == event.id);
            if (data.length > 0)
                data = data[0];
            /*console.log(data);
            console.log(data.id);
            console.log(data.lesson.id);*/

            this.editSyllabusRequest.id = data.id;
            this.editSyllabusRequest.items = data.attandance;
            this.editSyllabusRequest.classId = data.class.id;
            this.editSyllabusRequest.lessonId = data.lesson.id;
            this.editSyllabusRequest.teacherId = data.teacher.id;
            this.editSyllabusRequest.date = data.date;
            this.editSyllabusRequest.startTime = data.startTime;
            this.editSyllabusRequest.endTime = data.endTime;
            this.editPanel = true;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
